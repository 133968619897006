<template>
	<div class="rental container mx-auto">
		<section class="rental-top-block pt-0 lg:pt-4 xl:px-0">
			<div class="page-top mx-auto h-5/6 flex flex-col justify-between lg:hidden relative">
				<div class="slider-wrapper w-full mobile-top block lg:hidden mx-auto relative">
					<swiper
						v-if="mobileTopSlider.images.length > 0"
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:direction="'horizontal'"
						:autoplay="{
                            'delay': 5000,
                            'disableOnInteraction': false
                        }"
						@swiper="setMobileTopSwiper"
						@slideChange="onMobileTopSlideChange"
						class=""
					>
						<swiper-slide
							v-for="image in mobileTopSlider?.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>
					<bullets
						v-if="mobileTopSlider.images.length > 0"
						:count="mobileTopSlider?.images?.length"
						:activeIndex="activeMobileTopIndex"
						color="red3"
						direction="row"
						@bulletIndex="mobileTopSlideTo"
						class="flex mr-4 ml-auto relative right-0 justify-end"
					></bullets>
				</div>
			</div>
            <img
                class="mobile-title block lg:hidden mx-auto relative"
                src="../assets/img/mobile-rental-title.svg"
                alt=""
            >
			<div class="rental-content w-full relative h-full pb-16">
				<div class="rental-content-slider-top w-full h-full relative">
					<!-- top swiper -->
					<swiper
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:autoplay="{
  'delay': 5000,
'disableOnInteraction': false
}"
						@swiper="setTopSwiper"
						@slideChange="onTopSlideChange"
                        class="hidden lg:block"
					>
						<swiper-slide
							v-for="image in topSlider?.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>
					<!-- top slider bullets -->
					<bullets
						:count="topSlider?.images?.length"
						:activeIndex="activeTopIndex"
						color="red3"
						direction="row"
						@bulletIndex="topSlideTo"
						class="bullets hidden lg:flex lg:container lg:mx-auto lg:relative lg:left-auto absolute left-0 pt-4 "
					></bullets>
					<img
						src="../assets/img/mobile-rental-image-01.png"
						alt=""
						class="block lg:hidden mx-auto mix-blend-multiply"
					>
					<div class="content-text relative lg:absolute flex items-center w-full lg:w-2/5 xl:w-96 top-0 bottom-0 h-full right-0 z-10 justify-center mt-12 lg:mt-0 lg:mr-8 text-left">
						<div class="content-wrapper flex flex-col lg:justify-center">
							<div class="hidden lg:block rental-header relative">
								<div class="header-text header-text-overlay text-2xl font-title font-normal leading-tight top-0 lg:left-0 left-4 z-10 lg:mt-6">
									<h1 class="header-text-top text-left text-about">Facility Rental</h1>
								</div>
							</div>
							<h2 class="content-text-title hidden lg:block">{{ topContent.title }}</h2>
							<span class="content-decoration bg-about hidden lg:block"></span>
							<p
								class="content-text-description text-md"
								v-html="topContent.subTitle"
							></p>
							<img
								class="mx-auto mt-4 hidden 2xl:block"
								src="../assets/img/arrow-down.svg"
								alt=""
							>

						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- original bottom section -->
		<section class="rental-bottom-block pt-12 xl:pt-16 2xl:pt-4 4xl:px-0">
			<div class="rental-header relative">
				<div class="mobile-title lg:hidden flex justify-center">
					<img
						src="../assets/img/mobile-rental-plan.svg"
						alt=""
					>
				</div>
			</div>
			<div class="rental-plan flex flex-col lg:flex-none lg:grid grid-flow-col grid-rows-1 grid-cols-4 gap-16 lg:gap-4 pt-8 px-6 lg:px-0">
				<div class="header-text relative top-0 left-0 z-10 mt-0 font-title hidden lg:flex flex-col bg-red3 p-8">
					<div class="rental-header-append text-left flex items-end">
						<h1 class="rental-header-append-top text-about text-xl">Activity Highlights</h1>
					</div>
					<div class="header-text flex flex-col">
						<h1 class="content-text-title text-left text-white tracking-wider">{{ planImages?.title }}</h1>
						<span class="content-decoration bg-about"></span>
					</div>
					<div
						class="plan py-8  text-left bg-red3 text-white leading-loose tracking-wider relative row-span-1 lg:py-4"
						v-for="(item, index) in plan.items"
						:key="item.id"
					>
						<h1 class="plan-title text-2xl text-about leading-normal font-title">{{ alphabetMapping(index) }}</h1>
						<p class="plan-price text-md font-body">{{ item?.name }} NT.{{ item?.price }} {{ index === 0 ? '/ 小時' : '/ 日'}}</p>
						<p
							class="plan-contents text-md font-body  "
							v-html="item?.contents"
						></p>
					</div>
				</div>
				<div
					class="plan px-8 py-8 flex flex-col justify-center lg:hidden text-left bg-red3 text-white leading-loose tracking-wider relative row-span-1 lg:py-4"
					v-for="(item, index) in plan.items"
					:key="item.id"
				>
					<h1 class="plan-title text-2xl text-about leading-normal font-title">{{ alphabetMapping(index) }}</h1>
					<p class="plan-price text-md font-body">{{ item?.name }} NT.{{ item?.price }} {{ index === 0 ? '/ 小時' : '/ 日'}}</p>
					<p
						class="plan-contents text-md font-body  "
						v-html="item?.contents"
					></p>
					<img
						v-if="index == 0"
						src="../assets/img/mobile-rental-assets-03.png"
						alt=""
						class="block lg:hidden absolute -top-16 -right-8"
					>
					<img
						v-if="index == 1"
						src="../assets/img/mobile-rental-assets-04.png"
						alt=""
						class="block lg:hidden absolute -top-16 right-8"
					>
				</div>
				<div
					class="plan-image row-span-1 hidden lg:flex"
					v-for="image in planImages?.images"
					:key="image.id"
				>
					<img
						:src="image?.url"
						alt=""
					>
				</div>
			</div>
		</section>
		<!-- original middle section -->
		<section class="rental-middle-block pt-12 lg:pt-4 lg:pb-12 xl:px-0">
			<div class="rental-content flex-col flex lg:flex-none lg:grid grid-cols-8 gap-0 lg:gap-4 relative h-full">
				<div class="slider-middle-left h-full col-span-3 relative px-6 lg:px-0">
					<template v-if="middleLeftSlider?.images.length > 0">
						<img
							class="w-full h-full object-cover"
							:src="middleLeftSlider?.images[0]?.url"
							alt=""
						>
					</template>

				</div>
				<div class="slider-middle-right h-full col-span-3 relative px-6 lg:px-0 mt-8 lg:mt-0">
					<template v-if="middleRightSlider?.images.length > 0">
						<img
							class="w-full h-full object-cover"
							:src="middleRightSlider?.images[0]?.url"
							alt=""
						>

					</template>

				</div>
				<div class="mobile-title mt-24 lg:mt-0 lg:hidden flex justify-center">
					<img
						src="../assets/img/mobile-rental-booking.svg"
						alt=""
					>
				</div>
				<div class="content-text relative flex items-center w-full col-span-2 top-0 bottom-0 h-full left-0 z-10 justify-center mt-8 lg:mt-0 lg:ml-0 text-left">
					<div class="h-full bg-red3 w-full p-12 flex flex-col">
						<div class="hidden lg:block rental-header relative">
							<div class="header-text header-text-overlay font-title top-0 right-4 lg:right-0 z-10 mt-6 flex flex-col ">
								<h1 class="header-text-top text-left text-2xl text-about">Facility Rental</h1>
							</div>
						</div>
						<h2 class="content-text-title hidden lg:block">{{ middleContent?.title }}</h2>
						<span class="content-decoration bg-white hidden lg:block"></span>
						<p
							class="hidden lg:block content-text-description text-base 2xl:text-md"
							v-html="middleContent?.subTitle"
						></p>
						<p
							class="lg:hidden content-text-description text-base 2xl:text-md"
							v-html="joinParagraphs(middleContent?.subTitle)"
						></p>
						<a
							:href="middleContent?.subDescription"
							class="content-text-description-link text-base 2xl:text-md"
							target="_blank"
						>{{ middleContent?.description }}</a>
					</div>
				</div>
				<div class="slider-wrapper mt-16 w-full mobile-top block lg:hidden mx-auto relative">
					<swiper
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:direction="'horizontal'"
						:autoplay="{
  'delay': 5000,
  'disableOnInteraction': false
}"
						@swiper="setMobileMiddleSwiper"
					>
						<swiper-slide
							v-for="image in mobileMiddleSlider?.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>
					<img
						v-if="isMobile && mobileMiddleSlider.images.length > 0"
						src="../assets/img/mobile-rental-assets-06.png"
						alt=""
						class="block lg:hidden absolute -top-16 left-0 right-0 z-10 mx-auto"
					>
					<bullets
						:count="mobileMiddleSlider?.images?.length"
						:activeIndex="activeMobileMiddleIndex"
						color="red"
						direction="row"
						@bulletIndex="mobileMiddleSlideTo"
						class="flex lg:hidden mr-4 ml-auto relative right-0 mb-16 justify-end"
					></bullets>
				</div>

			</div>
		</section>
	</div>

</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { fetchData } from "../utils/fetchApi";
import SwiperCore, {
	Navigation,
	Pagination,
	Autoplay,
	Controller,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Bullets from "../components/Bullets.vue";
import { joinParagraphs } from "../utils/helpers";

SwiperCore.use([Navigation, Pagination, Autoplay, Controller]);

export default {
	components: {
		Swiper,
		SwiperSlide,
		Bullets,
	},
	setup() {
		const store = useStore();
		const enabledMenuId = store.state.enabledMenuId;
		const isMobile = computed(() => store.state.isMobile);

		const topSliderId = ref(null);
		const topContentId = ref(null);
		const middleLeftSliderId = ref(null);
		const middleRightSliderId = ref(null);
		const mobileTopSliderId = ref(null);
		const mobileMiddleSliderId = ref(null);
		const mobileBottomSlider1Id = ref(null);
		const mobileBottomSlider2Id = ref(null);
		const middleContentId = ref(null);
		const planId = ref(null);
		const planImagesId = ref(null);

		const topSlider = reactive({});
		const topContent = reactive({});
		const middleLeftSlider = reactive({ images: [] });
		const middleRightSlider = reactive({ images: [] });
		const mobileTopSlider = reactive({ images: [] });
		const mobileMiddleSlider = reactive({ images: [] });
		const mobileBottomSlider1 = reactive({ images: [] });
		const mobileBottomSlider2 = reactive({ images: [] });
		const middleContent = reactive({
			title: "",
			subTitle: "",
			description: "",
			subDescription: "",
		});
		const plan = reactive({});
		const planImages = reactive({});

		const alphabetMapping = (index) => {
			const alpha = Array.from(Array(26)).map((e, i) => i + 65);
			const alphabet = alpha.map((x) => String.fromCharCode(x));
			return alphabet[index];
		};

		const idTitleObjArray = [
			{ id: topSliderId, title: "Facility Rental Top Slider" },
			{ id: topContentId, title: "Facility Rental Top Contents" },
			{
				id: middleLeftSliderId,
				title: "Facility Rental Middle Left Slider",
			},
			{
				id: middleRightSliderId,
				title: "Facility Rental Middle Right Slider",
			},
			{
				id: mobileTopSliderId,
				title: "Facility Rental Mobile Top Slider",
			},
			{
				id: mobileMiddleSliderId,
				title: "Facility Rental Mobile Middle Slider",
			},
			{
				id: mobileBottomSlider1Id,
				title: "Facility Rental Mobile Bottom Slider 1",
			},
			{
				id: mobileBottomSlider2Id,
				title: "Facility Rental Mobile Bottom Slider 2",
			},
			{ id: middleContentId, title: "Facility Rental Middle Contents" },
			{ id: planId, title: "Facility Rental Price List" },
			{ id: planImagesId, title: "Facility Rental Bottom Images" },
		];

		onMounted(async () => {
			await fetchData.menuBlocks(enabledMenuId, idTitleObjArray);
			await fetchData.slider(topSliderId, topSlider);
			await fetchData.slider(middleLeftSliderId, middleLeftSlider);
			await fetchData.slider(middleRightSliderId, middleRightSlider);
			await fetchData.slider(mobileTopSliderId, mobileTopSlider);
			await fetchData.slider(mobileMiddleSliderId, mobileMiddleSlider);
			await fetchData.slider(mobileBottomSlider1Id, mobileBottomSlider1);
			await fetchData.slider(mobileBottomSlider2Id, mobileBottomSlider2);
			await fetchData.content(topContentId, topContent);
			await fetchData.content(middleContentId, middleContent);
			await fetchData.priceList(planId, plan);
			await fetchData.slider(planImagesId, planImages);
		});

		// set swiper instances ref
		const topSwiper = ref(null);
		const mobileTopSwiper = ref(null);
		const controlledSwiper = ref(null);
		const mainSwiper = ref(null);
		const mobileMiddleSwiper = ref(null);

		// set @swiper instances
		const setControlledSwiper = (swiper) =>
			(controlledSwiper.value = swiper);
		const setTopSwiper = (swiper) => (topSwiper.value = swiper);
		const setMobileTopSwiper = (swiper) => (mobileTopSwiper.value = swiper);
		const setMainSwiper = (swiper) => (mainSwiper.value = swiper);
		const setMobileMiddleSwiper = (swiper) => {
			mobileMiddleSwiper.value = swiper;
		};

		// set Bullets slide to @bulletsIndex
		const topSlideTo = (index) => topSwiper.value.slideTo(index);

		const mobileTopSlideTo = (index) =>
			mobileTopSwiper.value.slideTo(index);

		const mainSlideTo = (index) => mainSwiper.value.slideTo(index);
		const mobileMiddleSlideTo = (index) =>
			mobileMiddleSwiper.value.slideTo(index);

		// set current slide index on <swiper>
		const activeTopIndex = ref(0);
		const activeMobileTopIndex = ref(0);
		const activeMainIndex = ref(0);
		const activeMobileMiddleIndex = ref(0);
		const onTopSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeTopIndex.value = swiper.activeIndex;
		};
		const onMobileTopSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeMobileTopIndex.value = swiper.activeIndex;
		};
		const onSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeMainIndex.value = swiper.activeIndex;
		};

		const onMobileMiddleSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeMobileMiddleIndex.value = swiper.activeIndex;
		};

		return {
			isMobile,
			topSlider,
			topContent,
			middleLeftSlider,
			middleRightSlider,
			mobileTopSlider,
			mobileMiddleSlider,
			mobileBottomSlider1,
			mobileBottomSlider2,
			middleContent,
			plan,
			planImages,
			alphabetMapping,
			controlledSwiper,
			mainSwiper,
			topSwiper,
			setControlledSwiper,
			setMainSwiper,
			setTopSwiper,
			setMobileTopSwiper,
			setMobileMiddleSwiper,
			mainSlideTo,
			topSlideTo,
			mobileTopSlideTo,
			mobileMiddleSlideTo,
			activeMainIndex,
			activeTopIndex,
			activeMobileTopIndex,
			activeMobileMiddleIndex,
			onSlideChange,
			onTopSlideChange,
			onMobileTopSlideChange,
			onMobileMiddleSlideChange,
			joinParagraphs,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.swiper-container {
	height: 100%;
	width: 100%;
}
.swiper-slide {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.swiper-wrapper {
	height: 100% !important;
	align-items: center !important;
}

.content {
	&-wrapper {
		background: rgba(198, 0, 0, 0.8);
		width: 100%;
		padding: 3rem;
		height: calc(100% - 4rem);
		img {
			max-width: 2.5rem;
		}
		@media screen and (min-width: 1024px) and (max-width: 1279px) {
			padding: 2rem;
		}
		@media (max-width: 1023px) {
			background: transparent;
			padding: 0 2rem;
		}
	}
	&-text {
		&-title {
			@apply text-white;
			@media (min-width: 1024px) {
				@apply text-xlxl font-medium leading-loose tracking-widest;
			}
			@media (max-width: 1023px) {
				@apply text-secondary;
			}
		}
		&-description {
			@apply text-white font-body;
			@media (max-width: 1023px) {
				@apply text-md text-white tracking-widest;
			}
		}
	}
}

a.content-text-description {
	@media (max-width: 1023px) {
		color: $red2 !important;
	}
}

.rental {
	&-top-block {
		height: calc(100vh - 20vh);
		@media (max-width: 1023px) {
			height: auto;
		}
		.swiper-container {
			height: 100%;
			@media (max-width: 1023px) {
				height: auto;
			}
		}
	}
	&-middle-block {
		height: auto;
		@media (max-width: 1023px) {
			height: auto;
		}
		a {
			text-underline-offset: 0.5rem;
			@apply underline text-lg text-about font-bold;
		}
		strong {
			@apply text-about font-bold;
		}
		.swiper-slide {
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.swiper-container {
			height: 100%;
			@media (max-width: 1023px) {
				height: auto;
			}
		}

		.swiper-wrapper {
			height: 100% !important;
			align-items: center !important;
		}
	}
	&-bottom-block {
		@media (max-width: 1023px) {
			height: auto;
		}
		.rental-header-append .content-decoration {
			@apply mt-0;
		}
	}
	&-plan {
		@media screen and (min-width: 1024px) {
			grid-template-columns: 35% 1fr 1fr;
		}
		@media screen and (min-width: 1280px) {
			grid-template-columns: 30% 1fr 1fr 1fr;
		}
		@media screen and (min-width: 1536px) {
			grid-template-columns: 20% 1fr 1fr 1fr;
		}
		@apply font-normal;
	}
}
.plan {
	@apply text-md font-header;
	&-image {
		&:last-child {
			@media screen and (min-width: 1024px) and (max-width: 1279px) {
				display: none;
			}
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.slider-wrapper {
	max-width: 364px;
}
</style>
<style lang="scss">
.rental-top-block {
	.content-text-description {
		@apply text-about;
		strong {
			@apply text-white;
		}
        @media (max-width: 1023px) {
			@apply text-red;
            strong {
                @apply text-red2;
            }
		}
	}
}
.rental-middle-block {
	a {
		@apply xl:text-lg lg:text-base text-md text-about font-normal leading-normal;
	}
	strong {
		@apply text-about font-normal;
	}
}
.rental-bottom-block {
	.plan-contents {
		strong {
			@apply text-about font-normal;
		}
	}
}
</style>